<template>
  <div class="column pt-0">
    <div class="has-background-white p-4">
      <h5 class="mb-4 has-text-weight-medium">ЗАРЕГИСТРИРОВАТЬ ЗАВОД</h5>
      <form
        action=""
        @submit.prevent="SentFactoryData"
      >
        <p class="mb-3">Введите данные</p>

        <b-field label="Компания">
          <b-select
            placeholder="Выберите компанию"
            v-model="selected_company"
            required
          >
            <option
              v-for="option in companies"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>


        <b-field label="Филиал">
          <b-select
            placeholder="Выберите филиал"
            v-model="selected_branch"
            required
          >
            <option
              v-for="option in branches"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

        <div class="control mt-4">
          <span class=""><b>Наименование завода</b></span>
          <input
            class="input"
            type="text"
            v-model="factory_name"
            placeholder="Наименование завода"
            required
          >
        </div>

        <button class="button is-info mt-5">Зарегистрировать</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companies: [],
      branches: [],
      selected_branch: null,
      selected_company: null,
      factory_name: null,
    };
  },
  watch: {
    selected_company() {
      this.GetBranch(this.selected_company);
    },
  },
  created() {
    this.GetCompany();
  },
  methods: {
    async GetCompany() {
      await this.$axios.get(`admin/companies`).then(response => {
        this.companies = response.data.data;

      });
    },
    async GetBranch(e) {
      await this.$axios.get(`admin/companies/${e}/branches/`).then(response => {
        this.branches = response.data.data;
      });
    },
    async SentFactoryData() {
      let data = {
        "name": this.factory_name,
        "branch_id": this.selected_branch,
      };
      await this.$axios.post(`admin/factories`, data).then(response => {
        this.$router.push("/factory-list");
      });
    },
  },
};
</script>

<style>

</style>

